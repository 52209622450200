import $ from 'jquery';
import Alpine from 'alpinejs';
import {encodeString} from "@/js/scripts/modal.js";

Alpine.data('modal', () => ({
    open: false,
    src: null,
    init() {
        window.addEventListener('openGlobalModal', (event) => {
            const modalId = event.detail?.modalId;

            if (modalId) {
                this.show(modalId);
            }
        });
    },
    handleEvent(e) {
        if (e.detail.postType === 'expert' && $('.post-collection')) {
            $('html, body').animate(
                {
                    scrollTop: $('.post-collection').offset().top - $('header').height(),
                },
                1000
            );
        }
    },
    show(id) {
        this.open = true;
        document.documentElement.classList.add('modal-open');
        this.load(id);
        this.saveModalToUrlQuery(id);
    },
    hide() {
        if (this.open) {
            this.open = false;
            document.documentElement.classList.remove('modal-open');
            this.src = null;
            this.saveModalToUrlQuery(null);
        }
    },
    load(id) {
        this.src = '/modals/' + id;
    },
    saveModalToUrlQuery(modal = null) {
        const params = new URLSearchParams(window.location.search);

        if (typeof modal !== 'string') {
            params.delete('omodal');
        } else {
            params.set('omodal', encodeString(modal));
        }
        if (Array.from(params).length === 0) {
            history.replaceState(history.state, document.title, location.pathname);
            return true;
        }
        history.replaceState(history.state, document.title, [location.pathname, params.toString()].join('?'));
        return true;
    },
}));
